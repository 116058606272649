import React, { useState, useEffect } from 'react';
import componentStyles from "./style.module.css"
import NativeWrapper from '../../js/native';

import API from '../../js/api'

let Native;
if(API.get("native") == true){
  Native = ({id, sendUrl}) => {
    const [native, setNative] = useState(0);

    useEffect(() => {
      hydrateAds(id, 0, true);
    }, [id]);

    function hydrateAds(id, count, rerender){
      if(!id.includes("unset")){
        if(rerender) {
          setNative(0);
        }
        NativeWrapper.getNative("nativeEndpoint").then((res) => {
          let adUnit = document.getElementById(id);
          if(adUnit){
            if(!adUnit.innerHTML.includes("svgrepo-com")){
              setNative(res);

              if(count < 5){
                if(Object.keys(res).length === 0){
                  setTimeout(() => {
                    hydrateAds(id, count + 1);
                  }, 2000);
                }
              }
            }
          }

          if(typeof sendUrl !== "undefined"){
            if(res.count === 1){
              sendUrl(res.statlink);
            }
          }

          let time = Math.round(Date.now() / 10000) | 0;
          if (typeof res.pixel != 'undefined'){
            let pixels = res.pixel.split('||');
            for (var j = 0; j < pixels.length; j++){
            	var pix = document.createElement('img');

            	pix.src = pixels[j].replace('[timestamp]', time);
            	pix.border = '0';
            	pix.height = '1';
            	pix.width = '1';
            	pix.style.display = 'none';
              let pixDropper = document.getElementById(id);
              if(pixDropper){
                pixDropper.appendChild(pix);
              }
            }
          }
        });
      }
    }

    return(
      <div className={componentStyles.native} id={id}>
        {native != 0 && native.company &&
          <div className={componentStyles.nativeInline}>
            <a href={native.statlink} className={componentStyles.nativeContent}>
              <img src={native.image} style={{backgroundColor: native.backgroundColor}} className={componentStyles.nativeImage}/>
              <div className={componentStyles.nativeText}>
                <div className={componentStyles.nativeCName}>{native.company} — {native.companyTagline}</div>
                <div className={componentStyles.nativeDescription}>{native.description}</div>
              </div>
            </a>
            <a href={native.statlink} className={componentStyles.nativeCTA} style={{color: native.ctaTextColor, backgroundColor: native.ctaBackgroundColor}}>{native.callToAction}</a>
            <a href={native.ad_via_link} className={componentStyles.nativeSP}>AD</a>
          </div>
        }
        {native == 0 &&
          <div className={componentStyles.nativeInline}>
            <div className={componentStyles.nativeContent}>
              <div className={componentStyles.nativeImagePlaceholder}></div>
              <div className={componentStyles.nativeText}>
                <div className={componentStyles.textPlaceholder} style={{width: "60%"}}></div>
                <div className={componentStyles.textPlaceholder} style={{width: "90%"}}></div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}else{
  Native = () => { return null; }
}

export default Native;
