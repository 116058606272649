import React, { Component } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import componentStyles from "./style.module.css"

const BreadCrumbs = ({listing, slug, type, page, count, num}) => {
  num = num ? num : 50;
  let maxPage = Math.ceil(count/num);
  if(page == "unset"){
    page = "!#-page-#!";
  }
  let currPage = page;
  let path = `/${listing}/${slug}/${type}`;
  if(!slug){
    path = `/${listing}/`;
  }
  if(slug == "unset" || type == "unset"){
    let path = `/vectors/!#-search-slug-#!/!#-type-#!`;
  }

  return(
    <div className={componentStyles.paging}>
      <div className={componentStyles.pagingCarrier}>
        <div>
          {currPage != 1 &&
            <Link to={`${path.endsWith("/") ? path : path + "/"}${Number(page) - 1}`} title="Previus Page">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="arcs"><path d="M15 18l-6-6 6-6"/></svg>
            </Link>
          }
          {currPage == 1 &&
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="arcs"><path d="M15 18l-6-6 6-6"/></svg>
          }
        </div>
        <span><span style={{fontWeight: 400}}>{currPage}</span> / {maxPage}</span>
        <div>
          {currPage != Math.ceil(count/num) &&
            <Link to={`${path.endsWith("/") ? path : path + "/"}${Number(page) + 1}`} title="Next Page">
              <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="arcs"><path d="M9 18l6-6-6-6"/></svg>
            </Link>
          }
          {currPage == Math.ceil(count/num) &&
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="#ccc" strokeWidth="2" strokeLinecap="round" strokeLinejoin="arcs"><path d="M9 18l6-6-6-6"/></svg>
          }
        </div>
      </div>
    </div>
  );
}

export default BreadCrumbs;
