import React, { Component } from "react";
import { graphql, Link, navigate } from "gatsby"

import componentStyles from "./style.module.css"
import API from '../../js/api'

class Header extends Component {

  state = {
    term: "",
    searching: false,
    recommendation: null
  }

  handleTermChange(term){
    clearTimeout(this.timeout);
    if(term.length > 1 && term != this.state.term){
      this.setState({searching: true});
      this.timeout = setTimeout(() => {
          this.setState({term, searching: true});
          this.searchTerm(term);
      }, 300);
    }

    if(term.length == 0){
      this.setState({term, searching: false, recommendation: null});
    }
  }

  searchTerm(term){
    fetch(`https://api.svgrepo.com/term/${term}`).then(res => res.json()).then(res => {
      res.sort((a, b) => {
        return a.title.length - b.title.length;
      });

      this.setState({searching: false, recommendation: res});
    })
  }

  handleSubmit(event) {
    let userInput = this.refs._searchInput.value.toLowerCase();
    if(userInput.endsWith("-") || userInput.endsWith(" ")){
      userInput = userInput.substr(0, userInput.length-1);
    }
    this.handleSearchEnd(userInput);

    let linkSafeUserInput = userInput.replace(/ /g, "-");
    navigate(`/vectors/${linkSafeUserInput}/`);

    event.preventDefault();
  }

  handleSearchEnd(term) {
    clearTimeout(this.timeout);
    this.setState({term: "", searching: false, recommendation: null});
    this.refs._searchInput.value = term;
  }


  render(){
    return(
      <>
        <div className={this.props.isHome ? componentStyles.headerHome : componentStyles.header}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <Link to={`/`}>
              <img className={componentStyles.logo} src={require("../../images/logo_png.svg")} alt={`${API.get("title")} - ${API.get("subtitle")}`}/>
            </Link>
            {this.props.isHome &&
              <Link to={`/saved/`}>
                <div className={componentStyles.headerLogin}>
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                  <span>Saved</span>
                </div>
              </Link>
            }
          </div>
          {this.props.isHome &&
            <>
              <h3>{API.get("heroText")}</h3>
              <p>{API.get("heroDesc")}</p>
            </>
          }
          <div className={componentStyles.searchHolder}>
            <div className={componentStyles.search}>
              {this.state.term.length > 1 &&
                <div className={componentStyles.pressEnter}>PRESS ENTER FOR MORE</div>
              }

              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" style={{transform: this.state.searching ? "scale(0)" : "scale(1)"}}>
                <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z"/>
              </svg>
              <img src={require("./spin.svg")} style={{opacity: this.state.searching ? 1 : 0}}/>
              <form onSubmit={(event) => this.handleSubmit(event)}>
                <input
                  type="text"
                  placeholder={"Search for vectors and icons..."}
                  defaultValue={this.state.term} onChange={(e) => this.handleTermChange(e.target.value)}
                  onFocus={(e) => this.handleTermChange(e.target.value)}
                  ref="_searchInput"/>
                <input type="submit" style={{display: "none"}}/>
              </form>
              <div className={(this.state.term == "") ? componentStyles.recommendation : componentStyles.recommendationActive}>
                <ul className={componentStyles.recommendationListing}>
                  {this.state.recommendation && this.state.recommendation.map((search, i) => {
                    return (
                      <Link key={"search-"+i} to={`/vectors/${search.title.toLowerCase().replace(/ /g, "-")}/`} onClick={() => this.handleSearchEnd(search.title)}>
                        <li>{search.title[0].toUpperCase() + search.title.substr(1)} <span>{search.count} vector{search.count != 1 ? "s":""}</span></li>
                      </Link>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          {!this.props.isHome &&
            <Link to={`/saved/`}>
              <div className={componentStyles.headerLogin}>
                <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
                <span>Saved</span>
              </div>
            </Link>
          }
        </div>
      </>
    );
  }
}

export default Header;
