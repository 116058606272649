import API from './api';

class NativeWrapper {
  constructor(){
  }

  getNative(endpointIdentifier){
    if(typeof window != "undefined"){
      let nativeEndpoint = API.get(endpointIdentifier);
      let ck = '';
      try { ck = decodeURIComponent(document.cookie) } catch (e) {};
      let day = ck.indexOf('_bsap_daycap='),
      	life = ck.indexOf('_bsap_lifecap=');
      day = day >= 0 ? ck.substring(day + 12 + 1).split(';')[0].split(',') : [];
      life = life >= 0 ? ck.substring(life + 13 + 1).split(';')[0].split(',') : [];

      if (day.length || life.length) {
      	let freqcap = [];
      	for (let i = 0; i < day.length; i++) {
      		let adspot = day[i];

      		// using an array here is ugly, but safer cross-browser than for(var i in...) from an obj
      		for (var found = -1, find = 0; find < freqcap.length && found == -1; find++)
      		if (freqcap[find][0] == adspot) found = find;
      		if (found == -1) freqcap.push([adspot, 1, 0]);
      		else freqcap[found][1]++;
      	}
      	for (var i = 0; i < life.length; i++) {
      		var adspot = day[i];

      		// using an array here is ugly, but safer cross-browser than for(var i in...) from an obj
      		for (var found = -1, find = 0; find < freqcap.length && found == -1; find++)
      		if (freqcap[find][0] == adspot) found = find;
      		if (found == -1) freqcap.push([adspot, 0, 1]);
      		else freqcap[found][2]++;
      	}

      	for (var i = 0; i < freqcap.length; i++)
      		freqcap[i] = freqcap[i][0] + ':' + freqcap[i][1] + ',' + freqcap[i][2];
      	if (freqcap.length) nativeEndpoint += '?freqcap=' + encodeURIComponent(freqcap.join(';'));
      }
      return fetch(nativeEndpoint, {cache: "no-cache"}).then(res => res.json()).then(res => {
        if(res.ads[0].statlink){
          this.servedCallback(res.ads[0].bannerid, res.ads[0].zoneid, true);
          return res.ads[0];
        }else{
          return {};
        }
      });
    }else{
      return {}
    }
  }

  servedCallback(banner, zone, freqcap){ // bannerid, zoneid, fc: true
  	var append = function(w, data, days) {
  			var c = document.cookie,
  				i = c.indexOf(w + '='),
  				existing = i >= 0 ? c.substring(i + w.length + 1).split(';')[0] + '%2C' : '',
  				d = new Date();
  			d.setTime(days * 3600000 + d);
  			data = existing + data;
  			data = data.substring(0, 2048);
  			document.cookie = w + '=' + data + '; expires=' + d.toGMTString() + '; path=\/';
  		};

  	if (freqcap) {
  		append('_bsap_daycap', banner, 1);
  		append('_bsap_lifecap', banner, 365);
  	}
  }
}

const _ntv = new NativeWrapper();
export default _ntv;
