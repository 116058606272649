import React, { Component } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import componentStyles from "./style.module.css"
import API from '../../js/api'

const BreadCrumbs = ({links}) => {

  return(
    <div className={componentStyles.breadCrumbs}>
      {links.map((crumb, i) => {
        if(i != 0){
          return(
            <div key={i}>
              <svg width={24} height={24} viewBox="0 0 24 24" fill={"#ddd"}>
                <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"/>
                <path fill="none" d="M0,0h24v24H0V0z"/>
              </svg>
              <Link to={crumb.link ? crumb.link : `/${API.get("searchUrlPath")}/${crumb.title.toLowerCase().replace(/ /g, "-")}/`}>
                <span>{crumb.title}</span>
              </Link>
            </div>
          )
        }else{
          return(
            <Link key={i} to={`/`}>
              <span>{crumb.title}</span>
            </Link>
          )
        }
      })}
    </div>
  );
}

export default BreadCrumbs;
