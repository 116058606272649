import React, { Component } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import Flexbar from '../Flexbar';
import componentStyles from "./style.module.css"
import API from '../../js/api'
const popularSearch = require("./popularSearch.json");
const slug = API.get("slug");

const Footer = ({uniqId}) => {

  return(
    <>
      <div className={componentStyles.popularContent}>
        <ul>
          {popularSearch.map((search, i) => {
            return (
              <li key={"popularSearch-"+i}>{search[0].toUpperCase() + search.substr(1)} Vectors</li>
            )
          })}
        </ul>
      </div>

      <div className={componentStyles.footer}>
        <div>

          <Link to={`/`}>
            <img src={require("../../images/logo_png.svg")} alt={`${API.get("title")} - ${API.get("subtitle")}`} style={{height: 33}}/>
          </Link>
          <span className={componentStyles.subtext}>{API.get("subtitle")}</span>

          <div className={componentStyles.footerLinks}>
            <Link to={"/"}>Home</Link>
            <Link to={"/"}>Blog</Link>
            <a href="https://upload.svgrepo.com" target="_blank"><b>Submit</b></a>
            <a href="https://svgapi.com" target="_blank">Icon API</a>
            <Link to={"/page/licensing"}>Licensing</Link>
            <Link to={"/page/contact"}>Contact</Link>
          </div>

          <div className={componentStyles.projects}>
            <div>Checkout our other projects:</div>
            {slug != "svgrepo" &&
              <a href="https://www.svgrepo.com" target="_blank">
                <img src={require("../../images/logo_svg.svg")} style={{height: 16}} alt={"SVG Repo - Search and find open source SVG graphics fastest way"}/>
              </a>
            }
            {slug != "pngrepo" &&
              <a href="https://www.pngrepo.com" target="_blank">
                <img src={require("../../images/logo_png.svg")} style={{height: 16}} alt={"PNG Repo - Search and find best Free PNG graphics"}/>
              </a>
            }
            {slug != "fontrepo" &&
              <a href="https://www.fontrepo.com" target="_blank">
                <img src={require("../../images/logo_font.svg")} style={{height: 16}} alt={"FONT Repo - Free curated Fonts for commercial projects"}/>
              </a>
            }
            {slug != "uiuxrepo" &&
              <a href="https://www.uiuxrepo.com" target="_blank">
                <img src={require("../../images/logo_uiux.svg")} style={{height: 16}} alt={"UIUX Repo - Free Design resources for Sketch, PSD, Adobe XD and Figma"}/>
              </a>
            }
            {slug != "iconsrepo" &&
              <a href="https://www.iconsrepo.com" target="_blank">
                <img src={require("../../images/logo_icons.svg")} style={{height: 16}} alt={"ICONS Repo - Easily costimisable Free Icons for commercial use"}/>
              </a>
            }
          </div>
        </div>
        <Flexbar id={uniqId} type={"footer"}/>
      </div>
    </>
  );
}

export default Footer;
