import React from "react"
import { Link } from "gatsby"

import API from '../js/api'
import makeid from '../js/makeid'
import './style.css'
import Helmet from "react-helmet";

import Header from '../components/Header';
import Footer from '../components/Footer';
import Flexbar from '../components/Flexbar';

class Layout extends React.Component {

  render() {
    const { location, title, children, isHome } = this.props
    let url = null;
    let idable = "unset";

    if(typeof children[0].props.links != "undefined"){
      if(children[0].props.links.length == 3){
        idable = children[0].props.links[2].link;
      }else{
        idable = children[0].props.links[1].link;
      }
    }

    if(API.get("iframe") && typeof window != "undefined"){
      url = window.location.href.replace("www.pngrepo.com", "www.svgrepo.com").replace("localhost:8000", "www.svgrepo.com").replace("/collection/", "/vectors/") + "&svg=true";
    }
    return (
      <>
        <Flexbar id={"top-"+idable}/>
        <div className="layout">
          <Header isHome={isHome}/>
          {this.props.children}
          {API.get("iframe") && <iframe src={url} style={{border: "0px solid #fff"}} scrolling="no" frameBorder="0" marginHeight="0px" marginWidth="0px" height="1px" width="1px"></iframe>}
          <Footer uniqId={"bottom-"+idable}/>
        </div>
      </>
    )
  }
}

export default Layout
