import React, { Component, useState } from "react";
import { useStaticQuery, graphql, Link } from "gatsby"

import slugify from 'slugify';

import API from '../../js/api';
import componentStyles from "./style.module.css"

const Node = ({vector, loading, state}) => {
  if(vector && !vector.title){
    vector.title = vector.slug;
  }
  if(vector && !vector.slug){
    vector.slug = slugify(vector.title.replace(/[*+~.()"!:@]/g, " "));
  }
  if(vector && !vector.url){
    vector.url = `https://www.pngrepo.com/png/${vector.id}/60/${vector.slug}.png`;
  }
  const [saved, setSaved] = useState(vector ? API.checkSavedStatus(vector.id) : false);

  return(
    <div className={componentStyles.Node}>
      <div className={componentStyles.NodeImage}>
        {loading && <div className={componentStyles.loading}></div>}
        {vector &&
          <Link to={`/svg/${vector.id}/${vector.slug}`} title={"Show "+ vector.title + " PNG File"}>
            <img src={vector.url} alt={vector.title + " PNG File"} title={vector.title + " PNG File"}/>
          </Link>
        }
      </div>
      <div className={componentStyles.action}>
        <svg className={saved ? componentStyles.saved: ""} onClick={() => { setSaved(!saved); API.saveVector(String(vector.id), vector.slug);}} title={vector && "Like "+vector.title + " PNG File"} width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path></svg>
        <a href={vector && vector.url.replace("/show/", "/download/").replace("/60/", "/512/")} title={vector && "Download "+vector.title + " PNG File"}>
          <svg width="21" height="21" viewBox="0 0 24 24" fill="none" stroke="#8899a4" strokeWidth="2" strokeLinecap="square" strokeLinejoin="arcs"><path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"></path></svg>
        </a>
      </div>
    </div>
  );
}

export default Node;
