import React, { useState, useEffect } from 'react';
import componentStyles from "./style.module.css"
import NativeWrapper from '../../js/native';
// dangerouslySetInnerHTML = {{__html: API.previusNative}}

import API from '../../js/api'

let Flexbar;
if(API.get("flexbar") == true){
  function hide(){
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    if(typeof window != "undefined"){
      window.localStorage.setItem("flexbar_hide", tomorrow.getTime());
    }
  }

  Flexbar = ({id, type}) => {
    const [native, setNative] = useState(0);

    useEffect(() => {
      hydrateAds(id, 0, true);
    }, [id]);


    function hydrateAds(id, count, rerender){
      if(!id.includes("unset")){
        if(rerender) {
          setNative(0);
        }
        if(typeof window != "undefined"){
          let hideTill = window.localStorage.getItem("flexbar_hide");
          let today = new Date().getTime();
          if(hideTill < today || type == "footer"){
            NativeWrapper.getNative("flexbarEndpoint").then((res) => {
              let adUnit = document.getElementById(id);
              if(adUnit){
                if(!adUnit.innerHTML.includes("svgrepo-com")){
                  setNative(res);
                  if(count < 5){
                    if(Object.keys(res).length === 0){
                      setTimeout(() => {
                        hydrateAds(id, count + 1);
                      }, 2000);
                    }
                  }

                  let time = Math.round(Date.now() / 10000) | 0;
                  if (typeof res.pixel != 'undefined'){
                    let pixels = res.pixel.split('||');
                    for (var j = 0; j < pixels.length; j++){
                      var pix = document.createElement('img');

                      pix.src = pixels[j].replace('[timestamp]', time);
                      pix.border = '0';
                      pix.height = '1';
                      pix.width = '1';
                      pix.style.display = 'none';
                      let pixDropper = document.getElementById(id);
                      if(pixDropper){
                        pixDropper.appendChild(pix);
                      }
                    }
                  }
                }
              }
            });
          }
        }
      }
    }

    return(
      <div className={type == "footer" ? componentStyles.flexbarFooter : componentStyles.flexbarHolder}>
        <div className={componentStyles.flexbar} id={id}>
          {native != 0 && native.company &&
            <div className={componentStyles.nativeInline}>
              <span className={componentStyles.flexbarClose}  style={{color: native.textColor}} onClick={() => {hide(); setNative(0)}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line x1="13" y1="1" x2="1" y2="13" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="1" y1="1" x2="13" y2="13" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></g></svg>
              </span>
              <a href={native.statlink} className={componentStyles.nativeContent} target="_blank">
                <div className={componentStyles.nativeText}>
                  <div className={componentStyles.nativeCName}>{native.companyTagline} 🎉</div>
                  <div className={componentStyles.nativeDescription}>{native.description}</div>

                  <div className={componentStyles.ctaHolder}>
                    <a href={native.statlink} className={componentStyles.nativeCTA} style={{color: native.ctaTextColor ? native.ctaTextColor : "#04573c", backgroundColor: native.ctaBackgroundColor ? native.ctaBackgroundColor : "#2bedae"}}>{native.callToAction}</a>
                    <a href={native.ad_via_link} className={componentStyles.nativeSP}>AD</a>


                    {native.count === 1 &&
                      <span className={componentStyles.flexbarCloseMobile}  style={{color: native.textColor}} onClick={() => {hide(); setNative(0)}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><line x1="13" y1="1" x2="1" y2="13" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><line x1="1" y1="1" x2="13" y2="13" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></g></svg>
                      </span>
                    }
                  </div>
                </div>
                <div className={componentStyles.nativeContentImage} style={{backgroundColor: native.backgroundColor}}>
                  <img src={native.logo} style={{width: 125, height: 50}} className={componentStyles.nativeImage}/>
                </div>
              </a>
            </div>
          }
        </div>
      </div>
    );
  }
}else{
  Flexbar = () => { return null; }
}

export default Flexbar;
