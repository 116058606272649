import React, { Component } from "react";
import Helmet from "react-helmet";
import favicon from '../../images/favicon.png';

import API from '../../js/api'

class SEO extends Component {
  render() {
    let currentTitle = this.props.meta.noMainTitle? this.props.meta.title :`${this.props.meta.title} - PNG Repo Free PNG Icons`;
    if(this.props.meta){
      if(!this.props.meta.image){
        this.props.meta.image = {
          url: `https://${API.get("domain")}/img/logo.svg`
        }
      }
    }
    const htmlAttributes = {
      lang: "en",
    };
    const schemaOrgJSONLD = [
      {
        "@context": "http://schema.org",
        "@type": "WebSite",
        url: `https://${API.get("domain")}/`,
        name: "PNG Repo - Free PNG Icons and Graphics",
        alternateName: "Free PNG Icons",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "http://www.pngrepo.com/vectors/{search_term_string}/",
          "query-input": "required name=search_term_string"
        }
      }
    ];
    schemaOrgJSONLD.push(
      {
        "@context": "http://schema.org",
        "@type": "Organization",
        "url": "https://www.pngrepo.com",
        "logo": "https://www.pngrepo.com/img/logo.svg"
      }
    );


    if (this.props.meta.breadCrumbs) {
      schemaOrgJSONLD.push(
        {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
            "@type": "ListItem",
            "position": 1,
            "item": {
              "@id": `https://www.pngrepo.com${this.props.meta.breadCrumbs[0].to}`,
              "name": `${this.props.meta.breadCrumbs[0].title}`
            }
          },{
            "@type": "ListItem",
            "position": 2,
            "item": {
              "@id": `https://www.pngrepo.com${this.props.meta.breadCrumbs[1].to}`,
              "name": `${this.props.meta.breadCrumbs[1].title}`
            }
          }]
        },
        {
          "@context": "http://schema.org",
          "@type": "BlogPosting",
          url: `${this.props.meta.slug}`,
          name: `${this.props.meta.title}`,
          headline: `${this.props.meta.title}`,
          author: "PNG Repo",
          publisher: "PNG Repo",
          image: {
            "@type": "ImageObject",
            url: `${this.props.meta.image.url}`,
          },
          description: `${this.props.meta.description}`,
        }
      );
    }
    if(this.props.additionalSchemaOrgJSONLD){
      schemaOrgJSONLD.push(this.props.additionalSchemaOrgJSONLD);
    }


    return (
      <Helmet htmlAttributes={htmlAttributes}>
        <meta http-equiv="content-language" content="en-US" />
        <link rel="profile" href="http://gmpg.org/xfn/11" />
        <meta name="theme-color" content="#6B9BD2"/>
        <meta name="mobile-web-app-capable" content="yes" />
        <title>{currentTitle}</title>
        <meta name="description" content={this.props.meta.description} />
        <meta name="keywords" itemprop="keywords" content={`${this.props.meta.title} Vectors, ${this.props.meta.title} Icons, monocolor ${this.props.meta.title} Vectors, multicolor ${this.props.meta.title} Vectors`} />
        {this.props.meta.image && <meta name="image" content={this.props.meta.image.url} /> }
        <link rel="shortcut icon" type="image/png" href={favicon} />
        <link rel="manifest" href="/manifest.json"/>

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="fb:app_id" content="1482783148707638"/>
        <meta property="og:url" content={this.props.meta.slug} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={currentTitle} />
        <meta property="og:description" content={this.props.meta.description} />
        {this.props.meta.image && <meta property="og:image" content={this.props.meta.image.url} /> }
        {this.props.meta.image && <meta property="og:image:width" content={this.props.meta.image.width} /> }
        {this.props.meta.image && <meta property="og:image:height" content={this.props.meta.image.height} /> }

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@pngrepo" />
        <meta name="twitter:title" content={currentTitle} />
        <meta name="twitter:description" content={this.props.meta.description} />

        {this.props.meta.image && <meta name="twitter:image" content={this.props.meta.image.url} /> }
        {this.props.meta.canonical && <link rel="canonical" href={this.props.meta.canonical} /> }

      </Helmet>
    );
  }
}

export default SEO;
