import makeid from './makeid';
import Event from './event';
import config from '../config';

class API {
  constructor(){
    console.log("API: SVG Repo Init, welcome!");
  }

  getSavedVectors(){
    let saved = this.getData("saved");
    if(saved){
      return JSON.parse(saved);
    }else{
      return [];
    }
  }

  checkSavedStatus(id){
    let saved = this.getData("saved");
    if(saved){
      if(saved.includes(`"${id}"`)){
        return true;
      }
    }
    return false;
  }

  saveVector(id, slug){
    let saved = this.getData("saved");
    if(saved){

      if(saved.includes(`"${id}"`)){
        saved = JSON.parse(saved);
        saved = saved.filter(s => (s.id != id));

        this.setData("saved", JSON.stringify(saved));
        return;
      }
      saved = JSON.parse(saved);
    }else{
      saved = [];
    }

    saved.push({id, slug});
    this.setData("saved", JSON.stringify(saved));
  }

  get(configIdentifier, replaceArray){
    let text = config[configIdentifier];
    if(typeof text != "undefined"){
      if(replaceArray){
        replaceArray.forEach((replaceItem, i) => {
          text = text.replace(new RegExp("\\$"+Number(i+1), "g"), replaceItem);
        });
      }
    }else{
      console.warn("Can't find the text identifier: ", configIdentifier)
    }
    return text;
  }


	// These are like kinda private;
  // But xxx it, use them in the general app, who cares.
  setData(key, data){
		// returns promise, might be useful, no need to listen tho.
    if(typeof window != "undefined"){
      return localStorage.setItem(key, data);
    }
  }

  getData(key){
    // returns promise
    if(typeof window != "undefined"){
      return localStorage.getItem(key);
    }
  }
}

const _api = new API();
export default _api;
