module.exports = {
  title: `PNG Repo`,
  subtitle: "Search and find best Free PNG graphics",
  slug: "pngrepo",
  domain: "www.pngrepo.com",
  shortDesc: "Free Quality PNG Icons and Graphics",
  heroText: "Browse 300.000+ PNG Icons and Graphics",
  heroDesc: "Explore, search and find the best fitting icons or graphics for your projects using wide variety vector library. Download free PNG Icons for commercial use.",
  homeMetaDesc: `Free Icons and Illustrations in PNG format. ✅ Download free mono or multi color graphics for commercial use. Search in 300.000+ Free PNG Icons and Grahpics.`,
  contentType: "PNG Icon",
  twitter: `@pngrepo`,
  native: true,
  nativeEndpoint: "https://www.pngrepo.com/native.json",
  flexbar: true,
  flexbarEndpoint: "https://www.pngrepo.com/flex.php",
  iframe: true,
  searchUrlPath: "vectors",

  homeContentTitle: "Icon Collections",
  homeContentDesc: "See our latest featured icon collections, choose the desired style of vector collections to list from the tabs. Browse 433 pages of icon collections by color and style.",

  collectionPretty: "Collection",
  collectionUrlPath: "collection",
  collectionLinkAction: "List PNG Icons",
  collectionTitle: "$1 Icons",
  collectionDescription: "Free $1 PNG Icon Collection and Illustrations. $1 icon collections and vector packs for Sketch, Adobe Illustrator, Figma and websites. Browse $2 vector icons about $1 term.",
  collectionMetaTitle: "$1 PNG Icons and Graphics",
  collectionMetaDescription: "Free transparent $1 icons and graphics in PNG format. Free download $1 PNG Icons for logos, websites and mobile apps, useable in Sketch or Adobe Illustrator. Browse Page $2 PNG icons about $1 term.",

  collectionsPretty: "Collections",
  collectionsUrlPath: "collections",
  collectionsTitle: "$1 Icon Collections",
  collectionsDescription: "See some insights into how to use PNG icons in most efficient way, download free vector packs with one click, get tips and tricks about PNG files and see our latest featured icons.",
  collectionsMetaTitle: "$1 PNG Icon Collections Page $2",
  collectionsMetaDescription: "List of $1 SVG graphics collections. See the $2 of $1 PNG Icon packs and collections listed by most resent additions.",

  similarDescription: "Checkout other $1 Icons with different styles in PNG vector library. If this vectors are not what you are looking for try searching for",
  collectionVectorDescription: "$1 PNG Icon is a part of $2 vector collection. Following vectors are from the same pack as this vector also checkout all $2 icons and graphics.",

  singleTitle: "$1 PNG Icon",
  singleDesc: "Free Download $1$2 PNG vector file in monocolor and multicolor type for Sketch or Illustrator from $1$2 Vectors png vector collection. $1$2 Vectors PNG vector illustration graphic art design format.",
  singleImageAlt: "$1 PNG Icon",
  singleMetaTitle: "$1 Vector SVG Icon",
  singleMetaDesc: "Free $1 Vector Icon in PNG format. ✅ Download Free $1 icon and graphics for commercial use. $1 SVG vector illustration graphic art design format. $2 vectors.",

  savedTitle: "Saved Files",
  savedLongTitle: "Your Saved Files",
  savedDesc: "Here is a list of vectors and icons you have saved. Click the like icon again to remove the vector from your saved vectors. This icons are stored in your browser local storage.",

  assetShowPrefix: "https://www.pngrepo.com/png/",
  assetShowSuffix: "png",
  assetDownloadPrefix: "https://www.pngrepo.com/download/",
  assetDownloadSuffix: "png",
  assetIdentifier: "PNG"
}
